import { Flat } from "@mgi-labs/preview";

const saintJulienFlat: Flat = new Flat({
  name: "SaintJulien",
  paper: {
    layer: { url: "BlackSatin.png", name: "paper" },
  },
  design: {
    recto: {
      color: {
        layer: { url: "color.png", name: "color", width: 1454, height: 2048 },
      },
      silverFoil: {
        layer: { url: "silverFoil.png", name: "Foil Silver" },
      },
      varnish: {
        layer: { url: "varnish.png", name: "Varnish" },
      },
    },
    mask: {
      url: "mask.png",
      width: 825,
      height: 1159,
    },
  },
});

export default saintJulienFlat;
