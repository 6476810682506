import classNames from "classnames";
import React from "react";
import styles from "./styles/Devis.module.scss";

import { useTranslation } from "react-i18next";

import closeimg from "../../images/icon/close.svg";

const Devis = ({handleToggleVisibleDevis,isVisibleDevis} : {
  handleToggleVisibleDevis: (
    event: React.SyntheticEvent<Element, Event> | React.KeyboardEvent<Element>
  ) => void;
  isVisibleDevis: Boolean | undefined;
}) => {
  /*const [isVisible, setChecked] = useState(false);
  const hideWelcome = () => setChecked((isVisible) => !isVisible);*/

  const { t } = useTranslation();
  return (
    <form className={classNames(styles.devis, isVisibleDevis === undefined ? "" : isVisibleDevis ? styles.divisvisible : styles.devinotvisible, "col-12")}>
      <div className={classNames(styles.scroll, "col-12")}>
      <div className={classNames(styles.titlezone, "col-12 row")}>
        <p className={classNames("col-10")}>
            {t("Devistitle")}
          </p>
        <div className={classNames(styles.close, "col-2")}>
          <div         
          onClick={(e) => {
            handleToggleVisibleDevis(e);
          }} >
            <img src={closeimg} alt="Close"/>
          </div>
        </div>
      </div>

      <div className={classNames(styles.infozone, "col-12")}>
        <div className={classNames(styles.titlesection, "col-12")}>
          <p>{t("Yourinfo")} :</p>
        </div>
        <div className={classNames(styles.infoform, "col-12")}>
          <div className={classNames(styles.oneinfo, "col-12")}>
            <label>{t("Entreprise")}<span>*</span> :</label>
            <input type="text" required/>
          </div>
          <div className={classNames(styles.oneinfo, "col-12")}>
            <label>{t("Email")}<span>*</span> :</label>
            <input type="text" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required/>
          </div>
          <div className={classNames(styles.oneinfo, "col-12")}>
            <label>{t("Phone")} :</label>
            <input type="tel" pattern="[0-9.+]" />
          </div>
        </div>
      </div>

      <div className={classNames(styles.numberzone, "col-12")}>
        <div className={classNames(styles.titlesection, "col-12")}>
          <p>{t("Nbcopies")}</p>
        </div>
        <div className={classNames(styles.numberform, "col-12")}>
          <div className={classNames(styles.number, "col-12")}>
            <label></label>
            <input type="number"/>
          </div>
        </div>
      </div>

      <div className={classNames(styles.recapzone, "col-12")}>
        <div className={classNames(styles.titlesection, "col-12")}>
          <p>{t("Recap")} :</p>
        </div>
        <div className={classNames(styles.recapform, "col-12")}>
        <div className={classNames(styles.onerecap, "col-12 row")}>
              <p className={classNames(styles.recapname, "col-6")}>Type :</p>
              <div className={classNames("col-6")}>
                <select name="Type" id="Type" className={classNames(styles.recapselect)}>
                  <option value="gold">Packaging</option>
                  <option value="silver">{t("bottleLabel")}</option>
                  <option value="coper">{t("flatSupport")}</option>
                </select>
              </div>
          </div>
          <div className={classNames(styles.onerecap, "col-12 row")}>
              <p className={classNames(styles.recapname, "col-6")}>{t("varnish")}</p>
              <div className={classNames("col-6")}>
                <p>Varnish</p>
              </div>
          </div>
          <div className={classNames(styles.onerecap, "col-12 row")}>
              <p className={classNames(styles.recapname, "col-6")}>Foil 1 :</p>
              <div className={classNames("col-6")}>
                <select name="Foil1" id="Foil1" className={classNames(styles.recapselect)}>
                  <option value="gold">{t("goldFoil")}</option>
                  <option value="silver">{t("silverFoil")}</option>
                  <option value="coper">{t("copperFoil")}</option>
                  <option value="red">{t("redFoil")}</option>
                  <option value="green">{t("greenFoil")}</option>
                  <option value="blue">{t("blueFoil")}</option>
                  <option value="pink">{t("pinkFoil")}</option>
                  <option value="orange">{t("orangeFoil")}</option>
                  <option value="black">{t("blackFoil")}</option>
                </select>
              </div>
          </div>
          <div className={classNames(styles.onerecap, "col-12 row")}>
              <p className={classNames(styles.recapname, "col-6")}>Foil 2 :</p>
              <div className={classNames("col-6")}>
                <select name="Foil2" id="Foil2" className={classNames(styles.recapselect)}>
                  <option value="gold">{t("goldFoil")}</option>
                  <option value="silver">{t("silverFoil")}</option>
                  <option value="coper">{t("copperFoil")}</option>
                  <option value="red">{t("redFoil")}</option>
                  <option value="green">{t("greenFoil")}</option>
                  <option value="blue">{t("blueFoil")}</option>
                  <option value="pink">{t("pinkFoil")}</option>
                  <option value="orange">{t("orangeFoil")}</option>
                  <option value="black">{t("blackFoil")}</option>
                </select>
              </div>
          </div>
          <div className={classNames(styles.onerecap, "col-12 row")}>
              <p className={classNames(styles.recapname, "col-6")}>{t("Paper")}</p>
              <div className={classNames("col-6")}>
                <select name="Paper" id="Paper" className={classNames(styles.recapselect)}>
                  <option value="wm">{t("WhiteMatte")}</option>
                  <option value="bm">{t("BlackMatte")}</option>
                  <option value="wg">{t("WhiteGloss")}</option>
                  <option value="bg">{t("BlackGloss")}</option>
                  <option value="wt">{t("WhiteTextured")}</option>
                  <option value="bt">{t("BlackTextured")}</option>
                  <option value="ws">{t("WhiteSatin")}</option>
                  <option value="bs">{t("BlackSatin")}</option>
                  <option value="recycled">{t("RecycledPaper")}</option>
                </select>
              </div>
          </div>
        </div>
      </div>
      </div>
      <div className={classNames(styles.buttons, "col-12 row")}>
        <button         
        onClick={(e) => {
            handleToggleVisibleDevis(e);
          }} 
           className={classNames(styles.cancel)}>{t("Cancel")}
           </button>

        <input 
          className={classNames(styles.submit)} value="Envoyer" type="submit"/>
      </div>
    </form>
  );
};

export default Devis;
