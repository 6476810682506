import { Flat } from "@mgi-labs/preview";


const luciaFlat: Flat = new Flat({
  name: "Lucia",
  paper: {
    layer: { url: "BlackSatin.png", name: "paper" },
  },
  design: {
    recto: {
      color: {        
        layer: { url: "color.png", name: "color", width: 2966, height: 4096 },
      },
      goldFoil: {
        layer: { url: "goldFoil.png", name: "Foil Gold" },
      },
      varnish: {
        layer: { url: "varnish.png", name: "Varnish" },
      },
    },
  },
});

export default luciaFlat;
