import classNames from "classnames";
import React, { FormEvent, useCallback, useRef, useState } from "react";
import TagManager from "react-gtm-module";
import { Link } from "react-router-dom";
// import vidpuissant from "../../images/landing/vidpuissant.gif";
import approvegif from "../../images/landing/approvegif.gif";
import bullet from "../../images/landing/bullet.svg";
import confidentialite from "../../images/landing/confidentialite.svg";
import crypte from "../../images/landing/crypté.png";
import donnee from "../../images/landing/donnee.svg";
//import apercu from "../../images/landing/apercu.svg";
//import ar from "../../images/landing/ar.svg";
//import ia from "../../images/landing/ia.svg";
import easy from "../../images/landing/easy.png";
import facebook from "../../images/landing/facebook.svg";
import intuitif from "../../images/landing/intuitifimg.jpg";
import linkedin from "../../images/landing/linkedin.svg";
import logomgiapprovew from "../../images/landing/logomgiapprovew.svg";
import mail from "../../images/landing/mail.svg";
import twitter from "../../images/landing/twitter.svg";
import viddashboard from "../../images/landing/viddashboard.gif";
import iconpagepres from "../../images/Logo/icon.png";
import imgpuissant from "../../images/Logo/iconpuissant.png";
//import logomgiapprove from "../../images/landing/logomgiapprove.svg";
//import logomgiapproveblue from "../../images/Logo/logo.svg";
import logomgiapproveblue from "../../images/Logo/logoo.png";
//import logomgiapprove from "../../images/landing/logomgiapprove.svg";
//import mgi from "../../images/Logo/logomgi.png";
import logooscar from "../../images/Logo/oscar.png";
import styles from "./styles/Explications.module.scss";

const Explications = () => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const [activeScrollButton, setActiveScrollButton] = useState<Boolean>(true);

  const [formSent, setFormSent] = useState(false);

  const handleScroll = useCallback(() => {
    if (scrollRef.current && scrollRef.current?.scrollTop > 0) {
      setActiveScrollButton(false);
    } else {
      setActiveScrollButton(true);
    }
  }, [setActiveScrollButton]);

  const contactFormSubmitHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const target = event.target as typeof event.target & {
        name: { value: string };
        company: { value: string };
        email: { value: string };
        role: { value: string };
      };
      const json = {
        name: target.name.value,
        company: target.company.value,
        role: target.role.value,
        email: target.email.value,
      };
      TagManager.dataLayer({
        dataLayer: {
          event: "contact_form_submitted",
        },
      });
      postContactFormData(json);
      setFormSent(true);
    },
    []
  );

  return (
    <div className={classNames(styles.explications, "col-12")}>
      <div className={classNames(styles.navbar, "col-12")}>
        <div className={classNames(styles.logo, "col-12")}>
          <img
            className={classNames(styles.mgi)}
            src={logomgiapproveblue}
            alt="Logo MGI"
          />
          <img src={logooscar} alt="Logo oscar de l'emballage 2020" />
        </div>
      </div>
      <div
        className={classNames(styles.divcontent, "col-12")}
        ref={scrollRef}
        onScroll={() => handleScroll()}
      >
        {activeScrollButton && (
          <div className={classNames(styles.demo, styles.section05)}>
            <a href="#section06">
              <span></span>
              {/*Scroll*/}
            </a>
          </div>
        )}
        <div className={classNames(styles.content, "col-12")}>
          {/*  <div className={classNames(styles.logooscar, "col-12")}>
         <img src={logooscar} alt="Logo oscar de l'emballage 2020"/>
          </div> */}

          <div className={classNames(styles.titleprincipal, "col-12 row")}>
            {/*<h1 className={classNames("col-0")}></h1>*/}
            <div className={classNames(styles.contourbleu, "col-12")}>
              <h2>Découvrez le bon à tirer 4.0</h2>
            </div>
          </div>

          <div className={classNames(styles.video, "col-12")}>
            <Link to={`./creation/WhiskyDeer`}>
              <img src={approvegif} alt="Vidéo dashboard" />
            </Link>
          </div>
          {/* Unique */}

          <div
            className={classNames(
              styles.oneexplication,
              styles.margintop,
              styles.uniquespace,
              "col-12"
            )}
          >
            <h3>Unique</h3>
            <div
              className={classNames(
                styles.soustitre,
                styles.width100,
                "col-12"
              )}
            >
              <p>
                MGI Approve est la première application Cloud collaborative
                destinée aux Professionnels de l’Impression, aux Marques et aux
                Agences.
              </p>
            </div>
          </div>

          <div className={classNames(styles.liste, "col-12 row")}>
            <ul className={classNames("col-12 col-sm-8")}>
              <li>
                <img
                  src={bullet}
                  alt="Quelque soit le nombre d'interlocuteurs"
                />
                Promouvoir l'Ennoblissement numérique et l'impression: <br />
                Vernis Sélectif, Dorure, Données Variables
              </li>
              <li>
                <img src={bullet} alt="à distance " />
                Accélérer la commercialisation de la valeur ajoutée et se
                différencier
              </li>
              <li>
                <img src={bullet} alt="à tout moment" />
                Valider les maquettes en 3D: Bon à Tirer 4.0
              </li>
              <li>
                <img src={bullet} alt="à tout moment" />
                Quel que soit le nombre d'interlocuteurs ou de services.
              </li>
              <li>
                <img src={bullet} alt="à tout moment" />
                Multi-Sites, Multilingue <br />
                Quelles que soient les distances.
              </li>
              <li>
                <img src={bullet} alt="à tout moment" />À tout moment sur
                n’importe quel support informatique
              </li>
              <li>
                <img src={bullet} alt="à tout moment" />
                Gérer des campagnes marketing
              </li>
            </ul>
            <div className={classNames(styles.imgpres, "col-12 col-sm-4")}>
              <img src={iconpagepres} alt="iconpagepres" />
            </div>
          </div>

          <div className={classNames(styles.arrow)}>
            <p>DÉCOUVREZ NOTRE APPLICATION</p>
            <div className={classNames(styles.wrapperinner)}>
              <div className={classNames(styles.scrolldown)}>
                <span className={classNames(styles.arrowdown)}></span>
              </div>
            </div>
          </div>
          <div className={classNames(styles.arrowmobilezone)}>
            <Link to={`./creation/WhiskyDeer`}>
              <div className={classNames(styles.arrowmobile)}>
                <p>Essayer notre application</p>
              </div>
            </Link>
          </div>

          {/*         <div className={classNames(styles.iconesection, "col-12 row")}>
            <div className={classNames(styles.oneicon, "col-4 row")}>
              <div className={classNames(styles.icon, "col-12")}>
                <img src={apercu} alt="Aperçu en 3D" />
              </div>
              <p className={classNames("col-12")}>Aperçu en 3D</p>
            </div>
            <div className={classNames(styles.oneicon, "col-4 row")}>
              <div className={classNames(styles.icon, "col-12")}>
                <img src={ar} alt="Réalité augmentée" />
              </div>
              <p className={classNames("col-12")}>Réalité augmentée</p>
            </div>
            <div className={classNames(styles.oneicon, "col-4 row")}>
              <div className={classNames(styles.icon, "col-12")}>
                <img src={ia} alt="Réalité augmentée" />
              </div>
              <p className={classNames("col-12")}>Intelligence artificielle</p>
            </div>
          </div>
        */}

          {/* FIN Unique */}

          {/* Puissant */}

          <div className={classNames(styles.oneexplication, "col-12")}>
            <h3>Puissant</h3>
            <div className={classNames(styles.soustitre, "col-12")}>
              <p>
                MGI Approve traite en quelques secondes et simplement vos
                fichiers PDF
              </p>
            </div>
            <div className={classNames(styles.corps, "col-12")}>
              <p>
                et les transforme en objets réalistes en 3 dimensions, que l'on
                peut manipuler à distance, d'un simple clic
              </p>
            </div>
          </div>

          <div className={classNames(styles.liste, "col-12")}>
            <ul>
              <li>
                <img src={bullet} alt="Trait de découpe" />
                Vernis sélectif à plat ou en relief
              </li>
              <li>
                <img src={bullet} alt="Trait de découpe" />
                Dorure à chaud à plat ou en relief
              </li>
              <li>
                <img src={bullet} alt="Trait de découpe" />
                Impression quadrichromie et +
              </li>
              <li>
                <img src={bullet} alt="Trait de découpe" />
                Traits de découpe et pliage
              </li>
            </ul>
          </div>

          <div className={classNames(styles.video, "col-12 col-md-10")}>
            <img src={imgpuissant} alt="Iocn dashboard" />
          </div>

          {/* FIN Puissant */}

          {/* Simple */}
          <div className={classNames(styles.oneexplication, "col-12")}>
            <h3>Simple</h3>
            <div className={classNames(styles.soustitre, "col-12")}>
              <p>
                MGI Approve s'utilise au travers d'un navigateur web, à partir
                de tout ordinateur, tablette ou smartphone.
              </p>
            </div>
            <div className={classNames(styles.corps, "col-12")}>
              <p>
                Son processus de validation intégrant discussion en ligne et
                annotations, reprend les standards des messageries grand public.
              </p>
            </div>
          </div>

          <div className={classNames(styles.iconesection, "col-10 row")}>
            <div className={classNames(styles.oneicon, "col-6 row")}>
              <img src={easy} alt="Easy" />
              <p className={classNames(styles.gras)}>Intuitif</p>
            </div>
          </div>

          <div className={classNames(styles.video, "col-12")}>
            <Link to={`./creation/WhiskyDeer`}>
              <img
                className={classNames(styles.intuitif)}
                src={intuitif}
                alt="Intuitif"
              />
            </Link>
          </div>

          <div className={classNames(styles.arrow)}>
            <p>DÉCOUVREZ NOTRE APPLICATION</p>
            <div className={classNames(styles.wrapperinner)}>
              <div className={classNames(styles.scrolldown)}>
                <span className={classNames(styles.arrowdown)}></span>
              </div>
            </div>
          </div>

          <div className={classNames(styles.arrowmobilezone)}>
            <Link to={`./creation/WhiskyDeer`}>
              <div className={classNames(styles.arrowmobile)}>
                <p>Essayer notre application</p>
              </div>
            </Link>
          </div>

          {/* FIN Simple */}

          {/* Economique */}
          <div className={classNames(styles.oneexplication, "col-12")}>
            <h3>ÉCONOMIQUE / ACCESSIBLE / FACILE</h3>
            <div className={classNames(styles.soustitre, "col-12")}>
              <p>
                MGI APPROVE ne nécessite aucun investissement informatique,
                s'utilise de façon intuitive avec très peu de formation et
                réduit très Fortement:
              </p>
            </div>
            {/*            <div className={classNames(styles.soustitre, "col-12")}>
              <p>
                MGI APPROVE réduit de
                <br />
                <ul>
                  <li>
                    <span className={classNames(styles.blue)}>80%</span> les
                    coûts d'épreuvage papier et d'expédition
                  </li>
                  <li>
                    <span className={classNames(styles.blue)}>50%</span> les
                    temps d'opérateur
                  </li>
                </ul>
              </p>
            </div>
              */}
          </div>

          <div className={classNames(styles.liste, "col-12")}>
            <ul>
              <li>
                <img src={bullet} alt="Trait de découpe" />
                les coûts de fabrication de maquettes et d'épreuvage
              </li>
              <li>
                <img src={bullet} alt="Trait de découpe" />
                les coûts d'expédition
              </li>
              <li>
                <img src={bullet} alt="Trait de découpe" />
                les délais d’approbation
              </li>
              <li>
                <img src={bullet} alt="Trait de découpe" />
                l’immobilisation des équipements et du personnel.
              </li>
            </ul>
          </div>

          <div className={classNames(styles.corps, "col-12")}>
            <p className={classNames(styles.bluetitre, "col-12")}>
              Mgi Approve fonctionne avec un simple abonnement évolutif et
              s'adapte à toutes les tailles d'entreprises quel que soit le
              nombre de clients ou de dossiers
            </p>
          </div>

          {/* FIN Economique */}

          {/* Accélérateur */}
          <div className={classNames(styles.oneexplication, "col-12")}>
            <h3>ACCÉLÉRATEUR de MARKETING DIGITAL</h3>
            <div className={classNames(styles.soustitre, "col-12")}>
              <p>
                MGI Approve permet à moindre coût et sans délais, de
                démultiplier le nombre de maquettes proposées à un ou plusieurs
                clients
              </p>
            </div>
          </div>

          <div className={classNames(styles.liste, "col-12")}>
            <ul>
              <li>
                <img src={bullet} alt="Trait de découpe" />
                créer simplement des campagnes marketing
              </li>
              <li>
                <img src={bullet} alt="Trait de découpe" />
                mesurer son impact en temps réel
              </li>
              <li>
                <img src={bullet} alt="Trait de découpe" />
                cibler la valeur ajoutée
              </li>
            </ul>
          </div>

          <div className={classNames(styles.corps, styles.bluetext, "col-12")}>
            <p className={classNames(styles.bluetitre)}>
              Grâce à MGI Approve vous démultipliez votre force de vente, sans
              investissements supplémentaires.
            </p>
          </div>

          <div className={classNames(styles.video, "col-12")}>
            <Link to={`./creation/WhiskyDeer`}>
              <img src={viddashboard} alt="Vidéo dashboard" />
            </Link>
          </div>

          <div className={classNames(styles.arrow)}>
            <p>DÉCOUVREZ NOTRE APPLICATION</p>
            <div className={classNames(styles.wrapperinner)}>
              <div className={classNames(styles.scrolldown)}>
                <span className={classNames(styles.arrowdown)}></span>
              </div>
            </div>
          </div>

          <div className={classNames(styles.arrowmobilezone)}>
            <Link to={`./creation/WhiskyDeer`}>
              <div className={classNames(styles.arrowmobile)}>
                <p>Essayer notre application</p>
              </div>
            </Link>
          </div>
          {/* FIN Accélérateur */}

          {/* Sécurisé */}
          <div className={classNames(styles.oneexplication, "col-12")}>
            <h3>Sécurisé</h3>
            <div className={classNames(styles.soustitre, "col-12")}>
              <p>MGI Approve est un service Cloud totalement sécurisé</p>
            </div>
            <div className={classNames(styles.corps, "col-12")}>
              <p>
                dont les données sont hébergées sur la plateforme OutScale de{" "}
                <b>Dassault Systèmes</b>. L'ensemble des échanges sont cryptés
                de bout en bout, garantissant ainsi une totale intégrité et
                confidentialité.
              </p>
            </div>
          </div>

          <div className={classNames(styles.iconesection, "col-12 row")}>
            <div className={classNames(styles.oneicon, "col-4 row")}>
              <div className={classNames(styles.icon, "col-12")}>
                <img src={donnee} alt="Protection" />
              </div>
              <p className={classNames("col-12")}>Données protégées</p>
            </div>
            <div className={classNames(styles.oneicon, "col-4 row")}>
              <div className={classNames(styles.icon, "col-12")}>
                <img src={confidentialite} alt="Confidentialité" />
              </div>
              <p className={classNames("col-12")}>Confidentialité</p>
            </div>
            <div className={classNames(styles.oneicon, "col-4 row")}>
              <div className={classNames(styles.icon, "col-12")}>
                <img src={crypte} alt="Réalité augmentée" />
              </div>
              <p className={classNames("col-12")}>Crypté</p>
            </div>
          </div>

          {/*         <div className={classNames(styles.iconesection, "col-10 row")}>
            <div className={classNames(styles.oneicon, "col-6 row")}>
              <img src={donnee} alt="Protection" />
              <p>Données protégées</p>
            </div>
            <div className={classNames(styles.oneicon, "col-6 row")}>
              <img src={confidentialite} alt="Confidentialité" />
              <p>Confidentialité</p>
            </div>
            </div>
          */}

          {/* FIN Sécurisé */}

          {/*          <div className={classNames(styles.oneexplication, "col-12")}>
            <h3>Accessible</h3>
            <div className={classNames(styles.soustitre, "col-12")}>
              <p>MGI APPROVE ne nécessite aucun investissement informatique,</p>
            </div>
            <div className={classNames(styles.corps, "col-12")}>
              <p>
                s'utilise après une très rapide formation, et fonctionne avec un
                simple abonnement s'adaptant à toute taille d'entreprise.
              </p>
            </div>
          </div>
            */}
        </div>

        {/* Formulaire */}
        <div className={classNames(styles.footersection, "col-12 row")}>
          <div className={classNames(styles.topfooter, "col-12 row")}>
            <div
              className={classNames(
                styles.left,
                "col-12 col-sm-12 col-md-9 row"
              )}
            >
              {formSent ? (
                <h4 className={classNames(styles.title, "col-12")}>
                  Formulaire envoyé !
                </h4>
              ) : (
                <>
                  <h4 className={classNames(styles.title, "col-12")}>
                    Vous êtes intérressé ?
                  </h4>
                  <form
                    className={classNames(
                      "contact-form",
                      styles.fomulaire,
                      "col-12 row"
                    )}
                    action="#"
                    onSubmit={contactFormSubmitHandler}
                  >
                    <label className={classNames("col-4")} htmlFor="name">
                      Nom / prénom * :
                    </label>
                    <input
                      className={classNames("col-12 col-md-8")}
                      type="text"
                      name="name"
                      id="name"
                      required
                      placeholder="Entrer votre nom et prénom"
                      autoComplete="name"
                    />

                    <label className={classNames("col-4")} htmlFor="company">
                      Entreprise * :
                    </label>
                    <input
                      className={classNames("col-12 col-md-8")}
                      type="text"
                      name="company"
                      id="company"
                      required
                      placeholder="Entrer le nom de l'entreprise"
                      autoComplete="organization"
                    />

                    <label className={classNames("col-4")} htmlFor="role">
                      Fonction * :
                    </label>
                    <input
                      className={classNames("col-12 col-md-8")}
                      type="text"
                      name="role"
                      id="role"
                      required
                      placeholder="Précisez votre fonction"
                      autoComplete="organization-title"
                    />

                    <label className={classNames("col-4")} htmlFor="email">
                      Adresse Mail * :
                    </label>
                    <input
                      className={classNames("col-12 col-md-8")}
                      type="email"
                      name="email"
                      id="email"
                      required
                      placeholder="Entrer votre addresse mail"
                      autoComplete="email"
                    />
                    <div
                      className={classNames(styles.agree, "col-12 row")}
                      data-validate="Veuillez accepter les conditions"
                    >
                      <input
                        className={classNames(styles.checkbox)}
                        type="checkbox"
                        name="agree"
                        required
                      />
                      <label
                        className={classNames(styles.labelagree)}
                        htmlFor="agree"
                      >
                        J'ai lu et j'accepte la :&nbsp;
                        <a
                          href="https://mgi-fr.com/support-technique/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          Politique de Confidentialité
                        </a>
                        . *
                      </label>
                    </div>

                    <div
                      className={classNames(styles.submitzone, "col-12 row")}
                    >
                      <img src={mail} alt="Email" />
                      <input
                        type="submit"
                        name="fsubmit"
                        id="fsubmit"
                        value="Envoyer"
                      />
                    </div>
                  </form>
                </>
              )}
            </div>
            <div
              className={classNames(
                styles.right,
                "col-12 col-sm-12 col-md-3 row"
              )}
            >
              <img
                className={classNames("col-6 col-sm-6 col-md-12")}
                src={logomgiapprovew}
                alt="Logo mgi Approve white"
              />
              <p className={classNames("col-6 col-sm-6 col-md-12")}>
                Rester au courant des nouveautés !
              </p>
            </div>
          </div>

          <div className={classNames(styles.bottom, "col-12 row")}>
            <div className={classNames(styles.copyright, "col-7")}>
              <p>
                Copyright 2020{" "}
                <a
                  href="https://mgi-fr.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MGI Digital
                </a>{" "}
                | All rights Reserved.
              </p>
            </div>

            <div className={classNames(styles.logo, "col-5")}>
              <a
                href="https://www.facebook.com/MGIonline"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="Facebook" />
              </a>
              <a
                href="https://twitter.com/MGI_HQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="Twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/mgi-digital-graphic-technology/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="Linkedin" />
              </a>
            </div>
          </div>
        </div>

        {/* FIN Formulaire */}
      </div>
    </div>
  );
};

export default Explications;

export function postContactFormData(json: {
  name: string;
  company: string;
  role: string;
  email: string;
}) {
  return fetch(
    new URL("/contact-forms", process.env["REACT_APP_BACKEND_URL"]).toString(),
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(json),
    }
  );
}
