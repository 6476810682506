import "bootstrap/dist/css/bootstrap.min.css";
import classNames from "classnames";
import Cookies from "js-cookie";
import { default as React, useRef } from "react";
import Catalogue from "./catalogue";
import Explications from "./explications";
import styles from "./styles/Home.module.scss";

const Landing = () => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className={classNames(styles.home, "col-12")}>
      <div className={classNames(styles.container, "container")}>
        <div className={classNames(styles.principal, "col-12 row")}>
          <div className={classNames(styles.landing)}>
            <Explications></Explications>
          </div>
          <div ref={ref} className={classNames(styles.catalogue)}>
            <Catalogue></Catalogue>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Landing;

const cookieName = "did-enter-contact-form";

export function didEnterForm(): boolean {
  return Cookies.get(cookieName) === "1";
}

export function rememberEnteredForm(): void {
  const expirationDate = new Date();
  expirationDate.setFullYear(expirationDate.getFullYear() + 1);
  Cookies.set(cookieName, "1", {
    expires: expirationDate,
  });
}
