import { Flat } from "@mgi-labs/preview";


const imagePlusFlat: Flat = new Flat({
  name: "ImagePlus",
  paper: {
    layer: { url: "BlackSatin.png", name: "paper" },
  },
  design: {
    recto: {
      color: {        
        layer: { url: "color.png", name: "color", width: 2048, height: 1305 },
      },
      goldFoil: {
        layer: { url: "goldFoil.png", name: "Foil Gold" },
      },
      varnish: {
        layer: { url: "varnish.png", name: "Varnish" },
      },
    },
    verso: {
      color: {        
        layer: { url: "color2.png", name: "color", width: 2048, height: 1305 },
      },
      goldFoil: {
        layer: { url: "goldFoil2.png", name: "Foil Gold" },
      },
      varnish: {
        layer: { url: "varnish2.png", name: "Varnish" },
      },
    },
  },
});

export default imagePlusFlat;
