import { Flat } from "@mgi-labs/preview";

const kiwanoFlat: Flat = new Flat({
  name: "Kiwano",
  paper: {
    layer: { url: "BlackSatin.png", name: "paper" },
  },
  design: {
    recto: {
      color: {
        layer: { url: "color.png", name: "color", width: 1512, height: 2048 },
      },
      goldFoil: {
        layer: { url: "goldFoil.png", name: "Foil Gold" },
      },
      varnish: {
        layer: { url: "varnish.png", name: "Varnish" },
      },
    },
    mask: {
      url: "mask.png",
      width: 850,
      height: 1153,
    },
  },
});

export default kiwanoFlat;
