import { Packaging } from "@mgi-labs/preview";
import { beYou } from "./beYou";
import { beYou2 } from "./beYou2";
import { beYou3 } from "./beYou3";
import { beYou4 } from "./beYou4";
import casque from "./casque";
import caviarFlat from "./caviarFlat";
import celestialFlat from "./celestialFlat";
import champagne from "./champagne";
import chateauDeFresnesFlat from "./chateauDeFresnesFlat";
import companyFlat from "./companyFlat";
import domusFlat from "./domusFlat";
import dragonFlat from "./dragonFlat";
import explorerFlat from "./explorerFlat";
import floralArtFlat from "./floralArtFlat";
import goldenWoodFlat from "./goldenWoodFlat";
import grappeFlat from "./grappeFlat";
import hairStyleFlat from "./hairStyleFlat";
import imagePlusFlat from "./imagePlusFlat";
import kiwanoFlat from "./kiwanoFlat";
import lagerBeerFlat from "./lagerBeerFlat";
import luciaFlat from "./luciaFlat";
import magnoliaFlat from "./magnoliaFlat";
import medardBox from "./medardBox";
import { mediflex } from "./mediflex";
import saintJulienFlat from "./saintJulienFlat";
import vodkaFlat from "./vodkaFlat";
import deerWhisky from "./whiskyDeer";
import whiteBloomFlat from "./whiteBloomFlat";
import victoryFlat from "./victory";
import boulangerieFlat from "./boulangerie";
import smileFlat from "./smileFlat";

export default function StaticPackagingRepository(name?: string): Packaging {
  switch (name) {
    case "Mediflex":
      return mediflex;
    case "BeYou":
      return beYou;
    case "beYou2":
      return beYou2;
    case "beYou3":
      return beYou3;
    case "beYou4":
      return beYou4;
    case "WhiskyDeer":
      return deerWhisky;
    case "Dragon":
      return dragonFlat;
    case "GoldenWood":
      return goldenWoodFlat;
    case "ChateauDeFresnes":
      return chateauDeFresnesFlat;
    case "Lucia":
      return luciaFlat;
    case "Domus":
      return domusFlat;
    case "Company":
      return companyFlat;
    case "FloralArt":
      return floralArtFlat;
    case "Caviar":
      return caviarFlat;
    case "Magnolia":
      return magnoliaFlat;
    case "ImagePlus":
      return imagePlusFlat;
    case "Celestial":
      return celestialFlat;
    case "Explorer":
      return explorerFlat;
    case "HairStyle":
      return hairStyleFlat;
    case "Kiwano":
      return kiwanoFlat;
    case "LagerBeer":
      return lagerBeerFlat;
    case "SaintJulien":
      return saintJulienFlat;
    case "Grappe":
      return grappeFlat;
    case "WhiteBloom":
      return whiteBloomFlat;
    case "Vodka":
      return vodkaFlat;
    case "medardBox":
      return medardBox;
    case "champagne":
      return champagne;
    case "casque":
      return casque;
    case "victory":
      return victoryFlat;
    case "smile":
      return smileFlat;
    case "boulangerie":
      return boulangerieFlat;
    default:
      return mediflex;
  }
}
