import { CustomizedProduct, Flat } from "@mgi-labs/preview";
import { beYou } from "../products/beYou";
import { beYou2 } from "../products/beYou2";
import { beYou3 } from "../products/beYou3";
import { beYou4 } from "../products/beYou4";
import boulangerieFlat from "../products/boulangerie";
import casque from "../products/casque";
import caviarFlat from "../products/caviarFlat";
import celestialFlat from "../products/celestialFlat";
import champagne from "../products/champagne";
import chateauDeFresnesFlat from "../products/chateauDeFresnesFlat";
import domusFlat from "../products/domusFlat";
import dragonFlat from "../products/dragonFlat";
import explorerFlat from "../products/explorerFlat";
import floralArtFlat from "../products/floralArtFlat";
import goldenWoodFlat from "../products/goldenWoodFlat";
import grappeFlat from "../products/grappeFlat";
import imagePlusFlat from "../products/imagePlusFlat";
import kiwanoFlat from "../products/kiwanoFlat";
import lagerBeerFlat from "../products/lagerBeerFlat";
import medardBox from "../products/medardBox";
import { mediflex } from "../products/mediflex";
import saintJulienFlat from "../products/saintJulienFlat";
import smileFlat from "../products/smileFlat";
import victoryFlat from "../products/victory";
import vodkaFlat from "../products/vodkaFlat";
import deerWhisky from "../products/whiskyDeer";
import whiteBloomFlat from "../products/whiteBloomFlat";

import pChateauFresnes from "../images/catalogue/1.jpg";
import goldenWood from "../images/catalogue/11.jpg";
import imagePlus from "../images/catalogue/12.jpg";
import pmediflex from "../images/catalogue/3.jpg";
import pBeYou from "../images/catalogue/4.jpg";
import pWhiskyDeer from "../images/catalogue/5.gif";
import pBeYou2 from "../images/catalogue/6.jpg";
import pBeYou3 from "../images/catalogue/7.jpg";
//import fcoiffeur from "../images/catalogue/flat/coiffeur.jpg";
import fmagnolia from "../images/catalogue/flat/flowerposter.jpg";
import fbird from "../images/catalogue/flat/bird.jpg";
import fdragon from "../images/catalogue/flat/dragon.jpg";
import fdumus from "../images/catalogue/flat/dumus.jpg";
import fexaprintcard from "../images/catalogue/flat/flat_card_exaprint.jpg";
import fexaprintlabel from "../images/catalogue/flat/flat_label-exaprint1.jpg";
import fexaprintmenu from "../images/catalogue/flat/flat_menu_exaprint.jpg";
import fflower from "../images/catalogue/flat/flower.jpg";
import fluoBeYou from "../images/catalogue/FluoBeYou.jpg";
import lbeer from "../images/catalogue/label/beer.jpg";
import lbeeranimal from "../images/catalogue/label/beeranimal.jpg";
import lcaviar from "../images/catalogue/label/caviar.jpg";
import lexplore from "../images/catalogue/label/explore.jpg";
import lvodka from "../images/catalogue/label/lvodka.jpg";
import lwhitebeer from "../images/catalogue/label/whitebeer.jpg";
import lwineblue from "../images/catalogue/label/wineblue.jpg";
import lwinegrape from "../images/catalogue/label/winegrape.jpg";
import pcasque from "../images/catalogue/packaging/casque.jpg";
//import fcompany from "../images/catalogue/9.jpg";
import pchampagne from "../images/catalogue/packaging/champagne.jpg";
import pmedard from "../images/catalogue/packaging/medard.jpg";
import magnoliaFlat from "../products/magnoliaFlat";
//import hairStyleFlat from "../products/hairStyleFlat";
//import companyFlat from "../products/companyFlat";

export type ShareLink = {
  name: string;
  url: string;
};

export type Book = {
  id: number;
  name: string;
  primaryColor: string;
  secondaryColor: string;
  url?: string;
  share?: ShareLink[];
  categories?: { id: number; name: string }[];
  contents?: {
    categoryId: number;
    product: CustomizedProduct | Flat;
    thumbnail: string;
  }[];
};

export const defaultBook: Book = {
  id: 1,
  name: "Etac",
  primaryColor: "#5286a2",
  secondaryColor: "#5286a2",
  url: "etac",
  categories: [
    { id: 1, name: "Packaging" },
    { id: 2, name: "Etiquette" },
    { id: 3, name: "Support à plat" },
  ],
  contents: [
    { categoryId: 1, product: deerWhisky, thumbnail: pWhiskyDeer },
    { categoryId: 1, product: medardBox, thumbnail: pmedard },
    { categoryId: 1, product: champagne, thumbnail: pchampagne },
    { categoryId: 1, product: mediflex, thumbnail: pmediflex },
    { categoryId: 1, product: beYou, thumbnail: pBeYou },
    { categoryId: 1, product: casque, thumbnail: pcasque },
    {
      categoryId: 2,
      product: chateauDeFresnesFlat,
      thumbnail: pChateauFresnes,
    },
    { categoryId: 2, product: kiwanoFlat, thumbnail: lbeeranimal },
    { categoryId: 2, product: lagerBeerFlat, thumbnail: lbeer },
    { categoryId: 2, product: vodkaFlat, thumbnail: lvodka },
    { categoryId: 2, product: whiteBloomFlat, thumbnail: lwhitebeer },
    { categoryId: 2, product: saintJulienFlat, thumbnail: lwineblue },

    { categoryId: 3, product: celestialFlat, thumbnail: fbird },
    { categoryId: 3, product: domusFlat, thumbnail: fdumus },
    { categoryId: 3, product: dragonFlat, thumbnail: fdragon },
    { categoryId: 3, product: goldenWoodFlat, thumbnail: goldenWood },
    { categoryId: 3, product: imagePlusFlat, thumbnail: imagePlus },
    { categoryId: 3, product: magnoliaFlat, thumbnail: fmagnolia },
  ],
};

export const exaprintBook: Book = {
  id: 1,
  name: "Exaprint",
  primaryColor: "#5286a2",
  secondaryColor: "#5286a2",
  url: "exaprint",
  categories: [
    { id: 1, name: "Packaging" },
    { id: 2, name: "Etiquette" },
    { id: 3, name: "Support à plat" },
  ],
  contents: [
    { categoryId: 1, product: medardBox, thumbnail: pmedard },
    { categoryId: 1, product: champagne, thumbnail: pchampagne },
    { categoryId: 1, product: mediflex, thumbnail: pmediflex },
    { categoryId: 1, product: beYou, thumbnail: pBeYou },
    { categoryId: 1, product: deerWhisky, thumbnail: pWhiskyDeer },
    { categoryId: 1, product: beYou2, thumbnail: pBeYou2 },
    { categoryId: 1, product: beYou3, thumbnail: pBeYou3 },
    { categoryId: 1, product: beYou4, thumbnail: fluoBeYou },
    { categoryId: 1, product: casque, thumbnail: pcasque },
    {
      categoryId: 2,
      product: chateauDeFresnesFlat,
      thumbnail: pChateauFresnes,
    },
    { categoryId: 2, product: kiwanoFlat, thumbnail: lbeeranimal },
    { categoryId: 2, product: caviarFlat, thumbnail: lcaviar },
    { categoryId: 2, product: explorerFlat, thumbnail: lexplore },
    { categoryId: 2, product: lagerBeerFlat, thumbnail: lbeer },
    { categoryId: 2, product: vodkaFlat, thumbnail: lvodka },
    { categoryId: 2, product: whiteBloomFlat, thumbnail: lwhitebeer },
    { categoryId: 2, product: saintJulienFlat, thumbnail: lwineblue },
    { categoryId: 2, product: grappeFlat, thumbnail: lwinegrape },
    { categoryId: 3, product: celestialFlat, thumbnail: fbird },
    { categoryId: 3, product: domusFlat, thumbnail: fdumus },
    { categoryId: 3, product: dragonFlat, thumbnail: fdragon },
    { categoryId: 3, product: floralArtFlat, thumbnail: fflower },
    { categoryId: 3, product: goldenWoodFlat, thumbnail: goldenWood },
    { categoryId: 3, product: imagePlusFlat, thumbnail: imagePlus },
    { categoryId: 3, product: victoryFlat, thumbnail: fexaprintcard },
    { categoryId: 3, product: smileFlat, thumbnail: fexaprintlabel },
    { categoryId: 3, product: boulangerieFlat, thumbnail: fexaprintmenu },
  ],
};

export function mapClientToBook(client: string) {
  return defaultBook;
}
