import { Flat } from "@mgi-labs/preview";

const vodkaFlat: Flat = new Flat({
  name: "Vodka",
  paper: {
    layer: { url: "BlackSatin.png", name: "paper" },
  },
  design: {
    recto: {
      color: {
        layer: { url: "color.png", name: "color", width: 1512, height: 2048 },
      },
      silverFoil: {
        layer: { url: "silverFoil.png", name: "Foil Silver" },
      },
      varnish: {
        layer: { url: "varnish.png", name: "Varnish" },
      },
    },
  },
});

export default vodkaFlat;
