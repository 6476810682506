import classNames from "classnames";
import React, { FormEvent, useCallback, useEffect } from "react";
import TagManager from "react-gtm-module";
import { useHistory } from "react-router-dom";
import "../../css/main.css";
import "../../css/util.css";
import logomgiapprove from "../../images/formulaire/logomgiapprove.svg";
import oscar from "../../images/formulaire/oscar.png";
import oscaremballage from "../../images/formulaire/oscaremballage.png";
import { postContactFormData } from "./explications";
import styles from "./styles/Devis.module.scss";

function Formulaire({ onSubmit }: { onSubmit: () => void }) {
  const history = useHistory();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "contact_form_component_rendered",
      },
    });
  }, []);

  const submitHandler = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const target = event.target as typeof event.target & {
        name: { value: string };
        company: { value: string };
        email: { value: string };
        role: { value: string };
      };
      const json = {
        name: target.name.value,
        company: target.company.value,
        role: target.role.value,
        email: target.email.value,
      };
      TagManager.dataLayer({
        dataLayer: {
          event: "contact_form_submitted",
          ...json,
        },
      });

      await postContactFormData(json);
      onSubmit();
    },
    [onSubmit]
  );

  return (
    <>
      <div className={classNames(styles.formulaire, "container-contact100")}>
        <div className={classNames("container-contact100")}></div>

        <form
          className={classNames("contact-form", "wrap-contact100")}
          onSubmit={submitHandler}
        >
          <div
            className={classNames(
              styles.backgroundimg,
              "contact100-form-title"
            )}
          >
            <span className={classNames("contact100-form-title-1")}>
              Bienvenue
            </span>

            <span className={classNames("contact100-form-title-2")}>
              Inscrivez-vous et testez notre l'application.
            </span>
          </div>

          <div className={classNames("imageform")}>
            <img src={logomgiapprove} alt="logomgiapprove" />
            <img className={classNames("littleimg")} src={oscar} alt="oscar" />
            <img
              className={classNames("littleimg")}
              src={oscaremballage}
              alt="oscaremballage"
            />
          </div>
          <div className={classNames("contact100-form validate-form")}>
            <div
              className={classNames("wrap-input100 validate-input")}
              data-validate="Nom et prénom requis"
            >
              <span className={classNames("label-input100")}>Nom:</span>
              <input
                className={classNames("input100")}
                type="text"
                name="name"
                placeholder="Entrer votre nom et prénom"
              />
              <span className={classNames("focus-input100")}></span>
            </div>

            <div
              className={classNames("wrap-input100 validate-input")}
              data-validate="Nom d'entreprise requis"
            >
              <span className={classNames("label-input100")}>Entreprise:</span>
              <input
                className={classNames("input100")}
                type="text"
                name="company"
                placeholder="Entrer le nom de l'entreprise"
                autoComplete="organization"
              />
              <span className={classNames("focus-input100")}></span>
            </div>

            <div
              className={classNames("wrap-input100 validate-input")}
              data-validate="Poste requis"
            >
              <span className={classNames("label-input100")}>Fonction:</span>
              <input
                className={classNames("input100")}
                type="text"
                name="role"
                placeholder="Précisez votre fonction"
                autoComplete="organization-title"
              />
              <span className={classNames("focus-input100")}></span>
            </div>

            <div
              className={classNames("wrap-input100 validate-input")}
              data-validate="Email valide requis : ex@abc.xyz"
            >
              <span className={classNames("label-input100")}>Email:</span>
              <input
                className={classNames("input100")}
                type="email"
                name="email"
                placeholder="Entrer votre adresse mail"
              />
              <span className={classNames("focus-input100")}></span>
            </div>
          </div>

          <div
            className={classNames(
              "col-12 row wrap-input100_agree validate-input"
            )}
            data-validate="Veuillez accepter les conditions"
          >
            <input
              className={classNames("checkbox")}
              type="checkbox"
              name="agree"
              required
            />
            <label className={classNames("labelagree")} htmlFor="agree">
              J'ai lu et j'accepte la{" "}
              <a
                href="https://mgi-fr.com/support-technique/"
                target="_blank"
                rel="noreferrer"
              >
                Politique de Confidentialité
              </a>
              . *
            </label>
          </div>

          <div className={classNames("container-contact100-form-btn")}>
            <button
              type="button"
              className={classNames(
                "contact100-form-btn contact100-form-btn-retour"
              )}
              onClick={() => history.goBack()}
            >
              <span>
                Retour
                <i
                  className={classNames("fa fa-long-arrow-right m-l-7")}
                  aria-hidden="true"
                ></i>
              </span>
            </button>

            <button className={classNames("contact100-form-btn")} type="submit">
              <span>
                Accéder
                <i
                  className={classNames("fa fa-long-arrow-right m-l-7")}
                  aria-hidden="true"
                ></i>
              </span>
            </button>
          </div>
        </form>
      </div>

      <div id="dropDownSelect1"></div>
    </>
  );
}

export default Formulaire;
