import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSpring } from "react-spring-web";
import { useGesture } from "react-use-gesture";
import { Handler } from "react-use-gesture/dist/types";
import { mapClientToBook } from "../../Domain/book";
import "../../Internationalization/i18n";
import Bottom from "./bottom";
import Left from "./left";
import Projects from "./projects";
// import Right from "./right";
import styles from "./styles/Home.module.scss";
// import Welcome from "./welcome";

const { sign, abs } = Math;

export type ActiveProjectType = "Packaging" | "Etiquette" | "Support à plat";

const WHEEL_THRESHOLD = 25;

export type UTMData = {
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  contactId: string;
};

const Catalogue = () => {
  const { projectType, clientName } = useParams<{
    projectType: ActiveProjectType;
    clientName: string;
  }>();

  const [
    selectedProjectType,
    setSelectedProjectType,
  ] = useState<ActiveProjectType>(projectType ?? "Packaging");

  const location = useLocation();
  const history = useHistory();

  const packagingRef = useRef<HTMLDivElement>(null);
  const bottleLabelRef = useRef<HTMLDivElement>(null);
  const flatSupportRef = useRef<HTMLDivElement>(null);

  const client = location.pathname !== "/" ? clientName : "/mgi";

  const scrollDivRef = useRef<HTMLDivElement>(null);

  // Prevent scroll when true
  const lockWheel = useRef(false);

  const activeProjectRef = mapProjectTypeToRef(selectedProjectType);

  const [, setY] = useSpring(
    () => ({
      y: activeProjectRef.current?.offsetTop,
      onChange: (y) => {
        scrollDivRef.current?.scrollTo(0, y.y ?? y);
      },
    }),
    [activeProjectRef, scrollDivRef]
  );

  function mapProjectTypeToRef(projectType: ActiveProjectType) {
    switch (projectType) {
      case "Packaging":
        return packagingRef;
      case "Etiquette":
        return bottleLabelRef;
      case "Support à plat":
        return flatSupportRef;
    }
  }

  const scrollToProjectType = useCallback(
    (activeProjectType: ActiveProjectType) => {
      if (activeProjectRef.current && !lockWheel.current) {
        setY({ y: activeProjectRef.current.offsetTop });
        setSelectedProjectType(activeProjectType);
        history.replace("/" + client + "/" + activeProjectType);
      }
    },
    [activeProjectRef, client, history, setY]
  );

  useEffect(() => {
    if (projectType) {
      if (activeProjectRef.current && activeProjectRef.current.offsetTop) {
        scrollDivRef.current?.scrollTo(0, activeProjectRef.current?.offsetTop);
      }
    }
  }, [activeProjectRef, projectType]);

  // How much we scrolled since the start of the movement
  const y = useRef(0);

  const triggerScrollIfIdleOrDecelerating: Handler<"wheel"> = ({
    delta: [, dy],
    direction: [, direction],
    memo: previousDy = 0,
  }) => {
    const isDecelerating =
      sign(dy) === sign(previousDy) && abs(dy) - abs(previousDy) <= 0;
    if (isDecelerating) {
      lockWheel.current = false;
      y.current = 0;
    } else if (!lockWheel.current) {
      if (abs(y.current) >= WHEEL_THRESHOLD) {
        scrollToNextProjectType(direction);
        lockWheel.current = true;
        y.current = 0;
      }
      y.current = y.current - dy;
    }
    return dy;
  };

  // Smart detection of scroll to another project type
  // Inspired by the following code:
  // https://codesandbox.io/s/react-use-gesture-wheel-clamp-urdco?file=/src/index.js:1442-1543
  const wheel = useGesture({
    onWheel: triggerScrollIfIdleOrDecelerating,
    onDrag: (props) => {
      // Invert scroll direction when dragging
      const [dirX, dirY] = props.direction;
      return triggerScrollIfIdleOrDecelerating({
        ...props,
        direction: [dirX, -dirY],
      });
    },
    onWheelEnd: () => {
      if (lockWheel.current) {
        lockWheel.current = false;
        y.current = 0;
      }
    },
  });

  const scrollToNextProjectType = useCallback(
    (direction: number) => {
      if (scrollDivRef.current) {
        if (selectedProjectType === "Packaging") {
          if (direction > 0) {
            scrollToProjectType("Etiquette");
          }
        } else if (selectedProjectType === "Etiquette") {
          if (direction > 0) {
            scrollToProjectType("Support à plat");
          } else {
            scrollToProjectType("Packaging");
          }
        } else if (selectedProjectType === "Support à plat") {
          if (direction < 0) {
            scrollToProjectType("Etiquette");
          }
        }
      }
    },
    [scrollToProjectType, selectedProjectType]
  );

  const book = mapClientToBook(client);

  return (
    <>
      {/* <Welcome></Welcome> */}
      <div
        className={classNames(styles.choosetype, "col-1 col-sm-1 col-md-2 row")}
      >
        <Left
          selectedProjectType={selectedProjectType}
          scrollToProjectType={scrollToProjectType}
          client={client}
          book={book}
        ></Left>
      </div>
      <div
        className={classNames(styles.content, "col-10 col-sm-10 col-md-8 row")}
        ref={scrollDivRef}
        {...wheel()}
      >
        <Projects
          packagingRef={packagingRef}
          bottleLabelRef={bottleLabelRef}
          flatSupportRef={flatSupportRef}
          client={client}
          book={book}
        ></Projects>
      </div>
      <div
        className={classNames(
          styles.filtershare,
          "col-1 col-sm-1 col-md-2 row"
        )}
      >
        {/* <Right></Right> */}
      </div>
      <Bottom></Bottom>
    </>
  );
};
export default Catalogue;
