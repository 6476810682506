import React from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import ChoixProjet from "./components/ChoixProjet";
import { FormContainer } from "./components/ChoixProjet/form-container";
import PreviewScene from "./components/ChoixProjet/previewScene";

function App() {
  const { i18n } = useTranslation();

  i18n.changeLanguage("fr");

  return (
    <BrowserRouter>
      <Switch>
        <Route path="*/creation/:projectName">
          <FormContainer>
            <PreviewScene />
          </FormContainer>
        </Route>
        <Redirect exact from="/" to="/mgi" />
        <Route path="/:clientName/:projectType?">
          <ChoixProjet />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
