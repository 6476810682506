import { Flat } from "@mgi-labs/preview";


const dragonFlat: Flat = new Flat({
  name: "Dragon",
  paper: {
    layer: { url: "BlackSatin.png", name: "paper" },
  },
  design: {
    recto: {
      color: {        
        layer: { url: "color.png", name: "color", width: 2952, height: 4174 },
      },
      goldFoil: {
        layer: { url: "goldFoil.png", name: "Foil Gold" },
      },
      varnish: {
        layer: { url: "varnish.png", name: "Varnish" },
      },
    },
  },
});

export default dragonFlat;
