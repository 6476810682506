import { CreationState, LayerView, Packaging } from "@mgi-labs/preview";
import { FoilMaterial } from "@mgi-labs/preview/dist/Domain/Foil";
import classNames from "classnames";
import React, { SyntheticEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import hiddenfoil from "../../images/icon/hiddenfoil.svg";
import hiddenfoilw from "../../images/icon/hiddenfoilw.svg";
import arrowbottom from "../../images/icon/arrowbottom.svg";
import arrowtop from "../../images/icon/arrowtop.svg";
//import changeimg from "../../images/icon/changeimg.svg";
// whiteG blackG whiteM blackM whiteT blackT whiteS blackS
import viewfoil from "../../images/icon/viewfoil.svg";
import viewfoilw from "../../images/icon/viewfoilw.svg";
import Logomgikm from "../../images/Logo/Logomgikm.svg";
import { mapFoilNameToFoilImg, mapPaperNameToPaperImg } from "./bottom3D";
import { backgroundColors } from "./right3D";
import styles from "./styles/Left3D.module.scss";

const Left3D = ({
  layers,
  setLayers,
  activeLayer,
  setActiveLayer,
  selectedPaperName,
  packaging,
  goldSelectedFoil,
  silverSelectedFoil,
  backgroundColor,
  client,
  isVisibleDevis,
  isVisibleFoilVar,
  setIsVisibleFoilVar,
}: {
  layers: CreationState["layersView"] | undefined;
  setLayers: React.Dispatch<React.SetStateAction<LayerView[]>>;
  activeLayer: string | undefined;
  setActiveLayer: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedPaperName: string;
  packaging: Packaging;
  goldSelectedFoil: FoilMaterial;
  silverSelectedFoil: FoilMaterial;
  backgroundColor: String;
  client: string;
  isVisibleDevis: Boolean | undefined;
  isVisibleFoilVar: Boolean | undefined;
  setIsVisibleFoilVar: React.Dispatch<React.SetStateAction<Boolean>>;
}) => {
  /*const [isVisible, setChecked] = useState(false);
  const hideWelcome = () => setChecked((isVisible) => !isVisible);*/
  const [isVisible, setChecked] = useState(false);
  const hideChoose = () => setChecked((isVisible) => !isVisible);
  const { t } = useTranslation();

  const handleDisableLayer = useCallback(
    (event: SyntheticEvent | KeyboardEvent, name?: string) => {
      if (!("key" in event) || event.key === "Enter") {
        const dispatchLayer: LayerView[] = [];
        layers &&
          layers.forEach((l) => {
            if (l.name === name) {
              dispatchLayer.push({ name: l.name, enable: !l.enable });
            } else {
              dispatchLayer.push({ name: l.name, enable: l.enable });
            }
          });
        setLayers(dispatchLayer);
      }
    },
    [layers, setLayers]
  );

  return (
    <div className={classNames(styles.allpage, "col-12")}>
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.content, "col-12 row")}>
          <div className={classNames(styles.infoentreprise, "col-12 row")}>
            <div className={classNames(styles.logoentreprise, "col-12")}>
              <Link to={`/${client}`}>
                <img src={"/client/mgi/logo.svg"} alt="Logo small catalogue" />
              </Link>
            </div>
          </div>
          <div className={classNames(styles.titlezone, "col-12 row")}>
            <div className={classNames(styles.nameentreprise, "col-12")}></div>
          </div>
          <div
            className={classNames(
              styles.choose,
              isVisibleDevis === undefined
                ? ""
                : isVisibleDevis
                ? styles.devinotvisible
                : styles.divisvisible,
                 !isVisibleFoilVar ? "" : styles.clickchoose,
              "col-12 row"
            )}
          >
            <div className={classNames(styles.choosetype, "col-12 row")}>
              <div className={classNames(styles.titlechange, "col-12")}>
                <h2
                  className={classNames(
                    styles.title,
                    backgroundColors.black === backgroundColor
                      ? styles.white
                      : ""
                  )}
                >
                  {packaging.name}
                </h2>
                <img
                  onClick={hideChoose}
                  src={!isVisible ? arrowtop : arrowbottom}
                  alt="Arrow"
                />
              </div>
              <div className={classNames(styles.change, isVisible ? styles.viewchoose : styles.hidechoose, "col-12")}>
                {layers &&
                  layers.map(
                    (l, index) =>
                      l.name !== "normalMap" && (
                        <div
                          key={index}
                          className={classNames(styles.typezone, "col-12")}
                        >
                          <div
                            className={classNames(
                              activeLayer === l.name && styles.typeselected,
                              l.name.includes("Foil")
                                ? styles.type
                                : styles.typeColorVarnish,
                              "col-12"
                            )}
                            onClick={(e) => {
                              if (l.name.includes("Foil"))
                                if (activeLayer === l.name) {
                                  setActiveLayer(undefined);
                                } else {
                                  setActiveLayer(l.name);
                                  setIsVisibleFoilVar(true);
                                }
                            }}
                          >
                            {l.name.includes("Foil") && (
                              <img
                                className={classNames(
                                  styles.silvercolor,
                                  styles.gold
                                )}
                                src={
                                  l.name === "silverFoil"
                                    ? mapFoilNameToFoilImg(
                                        silverSelectedFoil.name
                                      )
                                    : mapFoilNameToFoilImg(
                                        goldSelectedFoil.name
                                      )
                                }
                                alt=""
                              />
                            )}

                            <h2
                              className={classNames(
                                backgroundColors.black === backgroundColor
                                  ? styles.white
                                  : ""
                              )}
                            >
                              {l.name === "silverFoil"
                                ? t(silverSelectedFoil.name)
                                : l.name === "goldFoil"
                                ? t(goldSelectedFoil.name)
                                : l.name}
                            </h2>
                            <div
                              className={classNames(styles.viewseparation)}
                            />
                          </div>
                          <img
                            onClick={(e) => {
                              handleDisableLayer(e, l.name);
                            }}
                            className={classNames(
                              styles.view,
                              backgroundColors.black === backgroundColor
                                ? styles.invert
                                : ""
                            )}
                            src={
                              backgroundColors.black === backgroundColor
                                ? !l.enable
                                  ? hiddenfoilw
                                  : viewfoilw
                                : !l.enable
                                ? hiddenfoil
                                : viewfoil
                            }
                            alt="Cacher/afficher foil"
                          />
                          {/*IMAGE DE MAXIME <img className={classNames(styles.changeimg)} src={changeimg} alt="Icon change foil color or paper or varnish size"/>*/}
                        </div>
                      )
                  )}
                {/* Séparation entre couche papier et autres couches */}
                <div className={classNames(styles.separation)}></div>
                <div
                  className={classNames(
                    styles.type,
                    styles.papertype,
                    activeLayer === "paper" && styles.typeselected,
                    "col-12"
                  )}
                  onClick={(e) => {
                    console.log("coucou");
                    if (activeLayer === "paper") {
                      setActiveLayer(undefined);
                    } else {
                      setActiveLayer("paper");
                      setIsVisibleFoilVar(true);
                    }
                  }}
                >
                  <h2
                    className={classNames(
                      backgroundColors.black === backgroundColor
                        ? styles.white
                        : ""
                    )}
                  >
                    {t(selectedPaperName)}
                  </h2>
                  <img
                    className={classNames(styles.imgpaper)}
                    src={mapPaperNameToPaperImg(selectedPaperName)}
                    alt="Paper preview"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(styles.contactzone, "col-12 row")}>
            <div className={classNames(styles.contact)}>
              <a className={classNames(styles.contactus)} href="./">
                <img src={Logomgikm} alt="Logomgikm" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Left3D;
