import { PaperMaterial } from "@mgi-labs/preview";

interface ChoosePaper {
    [paperName: string]: PaperMaterial;
  }
  
  export const paperPicker: ChoosePaper = {
    RecycledPaper: {
      name: "RecycledPaper",
      envMapIntensity: 0,
      normalScaleFactor: 0.01,
      vecNormalScale: 4.0,
      roughness: 1,
    },
    WhiteGloss: {
      name: "WhiteGloss",
      envMapIntensity: 0.25,
      normalScaleFactor: 0.01,
      vecNormalScale: 4.0,
      roughness: 0,
      emissive: 0.06,
    },
    BlackGloss: {
      name: "BlackGloss",
      envMapIntensity: 0.25,
      normalScaleFactor: 0.01,
      vecNormalScale: 4.0,
      roughness: 0,
    },
    WhiteMatte: {
      name: "WhiteMatte",
      envMapIntensity: 0,
      normalScaleFactor: 0.01,
      vecNormalScale: 4.0,
      roughness: 1,
      emissive: 0.06,
    },
    BlackMatte: {
      name: "BlackMatte",
      envMapIntensity: 0,
      normalScaleFactor: 0.01,
      vecNormalScale: 4.0,
      roughness: 1,
    },
    WhiteTextured: {
      name: "WhiteTextured",
      envMapIntensity: 0.15,
      normalScaleFactor: 100,
      vecNormalScale: 0.25,
      roughness: 0,
      emissive: 0.05,
    },
    BlackTextured: {
      name: "BlackTextured",
      envMapIntensity: 0.15,
      normalScaleFactor: 100,
      vecNormalScale: 0.25,
      roughness: 0,
    },
    WhiteSatin: {
      name: "WhiteSatin",
      envMapIntensity: 0.35,
      normalScaleFactor: 0.05,
      vecNormalScale: 25.0,
      roughness: 0.5,
      emissive: 0.06,
    },
    BlackSatin: {
      name: "BlackSatin",
      envMapIntensity: 0.35,
      normalScaleFactor: 0.05,
      vecNormalScale: 25.0,
      roughness: 0.5,
    },
  };