import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { Book } from "../../Domain/book";
import styles from "./styles/Projects.module.scss";

const Projects = ({
  packagingRef,
  bottleLabelRef,
  flatSupportRef,
  client,
  book,
}: {
  packagingRef: React.RefObject<HTMLDivElement>;
  bottleLabelRef: React.RefObject<HTMLDivElement>;
  flatSupportRef: React.RefObject<HTMLDivElement>;
  client: string;
  book: Book;
}) => {
  function mapCategoryToRef(categoryId: number) {
    switch (categoryId) {
      case 1:
        return packagingRef;
      case 2:
        return bottleLabelRef;
      case 3:
        return flatSupportRef;
    }
  }

  return (
    <div className={classNames(styles.allpage, "col-12")}>
      {book.categories?.map((category) => (
        <div
          className={classNames(styles.container)}
          id={category.name}
          ref={mapCategoryToRef(category.id)}
        >
          <div className={classNames(styles.content, "col-12 row")}>
            {book.contents
              ?.filter((content) => content.categoryId === category.id)
              ?.map((content) => (
                <div
                  className={classNames(
                    styles.projet,
                    "col-sm-6 col-md-6 col-lg-6"
                  )}
                >
                  <div className={classNames(styles.image, "col-12")}>
                    <Link to={`./creation/${content.product.name}`}>
                      <img src={content.thumbnail} alt="Logo small catalogue" />
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Projects;
