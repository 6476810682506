import classNames from "classnames";
import {
  Coords3D,
  CreationState,
  PaperMaterial,
  PreviewScene,
} from "@mgi-labs/preview";
import { FoilMaterial } from "@mgi-labs/preview/dist/Domain/Foil";
import React, { useRef } from "react";
import { Canvas } from "react-three-fiber";
import { Object3D } from "three";
import styles from "./ChoixProjet/styles/Preview3D.module.scss";
//import { backgroundColors } from "./ChoixProjet/right3D";

const DEFAULT_CAMERA_POSITION: Coords3D = [-4, 4, 8];

export function Preview3D({
  previewState,
  goldSelectedFoil,
  silverSelectedFoil,
  selectedPaper,
  backgroundColor,
  isVisibleDevis,
  showOnBottle,
}: {
  previewState: CreationState;
  goldSelectedFoil: FoilMaterial;
  silverSelectedFoil: FoilMaterial;
  selectedPaper: PaperMaterial;
  backgroundColor: string;
  isVisibleDevis: Boolean | undefined;
  showOnBottle: boolean;
}) {
  const scene = useRef<Object3D>(null);

  return (
    <div
      className={classNames(
        styles.prev,
        isVisibleDevis ? styles.divisvisible : styles.devinotvisible
      )}
    >
      <Canvas
        style={{
          background: backgroundColor,
        }}
        camera={{ position: DEFAULT_CAMERA_POSITION }}
      >
        <PreviewScene
          forwardedRef={scene}
          creationState={previewState}
          opacity={1}
          foilMapping={{
            goldFoil: goldSelectedFoil,
            silverFoil: silverSelectedFoil,
          }}
          activePaper={selectedPaper}
          showOnBottle={showOnBottle}
        />
      </Canvas>
    </div>
  );
}
