import {
  BlankPackaging,
  CustomizedPackaging,
  deriveRotationAxes,
  DielineJson,
} from "@mgi-labs/preview";

export const minimalBlankFoldingDieline: DielineJson = {
  name: "mediflex",
  pdfURL: `${process.env.PUBLIC_URL}/mediflex/mediflex_dieline.pdf`,
  aiURL: `${process.env.PUBLIC_URL}/mediflex/mediflex_dieline.ai`,
  faceGeometries: {
    back: {
      height: 549.92,
      width: 2194.28,
      x: 1076.88,
      y: 1764.75,
    },
    bot: {
      height: 549.92,
      width: 2194.28,
      x: 1076.88,
      y: 1214.82,
    },
    front: {
      height: 549.92,
      width: 2194.28,
      x: 1076.88,
      y: 664.9,
    },
    left: {
      height: 546.7,
      width: 546.7,
      x: 530.18,
      y: 1764.75,
    },
    right: {
      height: 546.7,
      width: 546.7,
      x: 3271.16,
      y: 664.9,
    },
    top: {
      height: 549.92,
      width: 2194.28,
      x: 1076.88,
      y: 114.98,
    },
  },
  dimensions: { width: 4180, height: 2871 },
  foldingTree: {
    faceName: "bot",
    children: [
      {
        faceName: "back",
        children: [
          {
            faceName: "left",
          },
        ],
      },
      {
        faceName: "front",
        children: [
          {
            faceName: "right",
          },
          {
            faceName: "top",
          },
        ],
      },
    ],
  },
};

const blankFoldingMediflex: BlankPackaging = new BlankPackaging({
  dieline: deriveRotationAxes(minimalBlankFoldingDieline),
  paper: {
    layer: { url: "EskaDuoWhite.png", name: "paper" },
  },
});

export const mediflex: CustomizedPackaging = new CustomizedPackaging({
  dieline: blankFoldingMediflex.dieline,
  name: "mediflex",
  paper: blankFoldingMediflex.paper,
  design: {
    color: {
      layer: { url: "blob_full.jpg", name: "color" },
    },
    goldFoil: {
      layer: { url: "dorure_full.png", name: "Foil Gold" },
    },
    silverFoil: {
      layer: { url: "argent_full.png", name: "Foil silver" },
    },
    varnish: {
      layer: { url: "vernis_full.png", name: "Varnish" },
    },
  },
});
