
import classNames from "classnames";
import React from "react";
import styles from "./styles/Bottom.module.scss";



const Bottom = () => {


  return (
     <div className={classNames(styles.bottom, "col-12")}>
       
      </div>
  );
};

export default Bottom;
