import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

const gtmId = process.env["REACT_APP_GTM_ID"];
const gtmAuth = process.env["REACT_APP_GTM_AUTH"];
const gtmPreview = process.env["REACT_APP_GTM_PREVIEW"];
if (gtmId !== undefined) {
  TagManager.initialize({
    gtmId: gtmId ?? "",
    auth: gtmAuth,
    preview: gtmPreview,
  });
} else if (process.env.NODE_ENV !== "development") {
  throw new Error("Missing REACT_APP_GTM_ID, cannot initialize app");
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
