import React, { FunctionComponent, useCallback, useState } from "react";
import { didEnterForm, rememberEnteredForm } from ".";
import Formulaire from "./formulaire";

export const FormContainer: FunctionComponent = ({ children }) => {
  const [showForm, setShowForm] = useState(!didEnterForm());

  const submitHandler = useCallback(() => {
    rememberEnteredForm();
    setShowForm(false);
  }, []);

  return (
    <>
      {showForm ? <Formulaire onSubmit={submitHandler} /> : null}
      {children}
    </>
  );
};
