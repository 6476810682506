import {
  CreationState,
  DesignPage,
  LayerView,
  PaperMaterial,
} from "@mgi-labs/preview";
import {
  FoilMaterial,
  goldFoilMaterial,
  silverFoilMaterial,
} from "@mgi-labs/preview/dist/Domain/Foil";
import "bootstrap/dist/css/bootstrap.min.css";
import classNames from "classnames";
import React, {
  KeyboardEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import bottleOff from "../../images/icon/bottle_off.svg";
import bottleOn from "../../images/icon/bottle_on.svg";
import StaticPackagingRepository from "../../products/StaticPackagingRepository";
import Loader3D from "../Loader3D";
import { Preview3D } from "../Preview3D";
import Bottom3D from "./bottom3D";
import Devis from "./Devis";
import Left3D from "./left3D";
import { paperPicker } from "./paper";
import Right3D, { backgroundColors } from "./right3D";
import styles from "./styles/Home3D.module.scss";

const PreviewScene = () => {
  const { projectName } = useParams<{ projectName: string }>();
  const packaging = StaticPackagingRepository(projectName);
  const [layers, setLayers] = useState<LayerView[]>(
    createLawerView(packaging.design?.recto)
  );

  const location = useLocation();
  const client = location.pathname.split("/")[1];

  const { t } = useTranslation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "react_router_location_change",
      },
    });
  }, [location]);

  const previewState: CreationState = {
    packaging: packaging,
    smartLabels: [],
    gltfMode: false,
    cancelImport: false,
    shaderMaterialMode: true,
    layersView: layers ?? [],
    annotations: [],
    enableZoom: true,
  };

  const [backgroundColor, setBackgroundColor] = useState<string>(
    backgroundColors.grey
  );

  useEffect(() => {
    if (previewState.packaging.name === "WhiskyDeer") {
      setBackgroundColor(backgroundColors.black);
    }
  }, [previewState.packaging.name]);

  const [activeLayer, setActiveLayer] = useState<string>();

  const [goldSelectedFoil, setGoldSelectedFoil] = useState<FoilMaterial>(
    goldFoilMaterial
  );

  const [silverSelectedFoil, setSilverSelectedFoil] = useState<FoilMaterial>(
    silverFoilMaterial
  );

  const [selectedPaper, setSelectedPaper] = useState<PaperMaterial>(
    paperPicker.WhiteSatin
  );

  const [isVisibleDevis, setIsVisibleDevis] = useState<Boolean>();
  const handleToggleDevis = useCallback(
    (event: SyntheticEvent | KeyboardEvent) => {
      if (!("key" in event) || event.key === "Enter") {
        setIsVisibleDevis((isVisibleDevi) => !isVisibleDevi);
      }
    },
    []
  );
  const [showOnBottle, setShowOnBottle] = useState<boolean>(false);
  const handleShowOnBottle = useCallback(
    (event: SyntheticEvent | KeyboardEvent) => {
      if (!("key" in event) || event.key === "Enter") {
        setShowOnBottle((showOnBottle) => !showOnBottle);
      }
    },
    []
  );
  const [isVisibleFoilVar, setIsVisibleFoilVar] = useState<Boolean>(false);
  return (
    <>
      <Helmet>
        <title>
          MGI Approve - {t("project")} {projectName}
        </title>
      </Helmet>
      <div
        className={classNames(
          styles.home,
          isVisibleDevis === undefined
            ? ""
            : isVisibleDevis
            ? styles.divisvisible
            : styles.devinotvisible,
          "col-12"
        )}
      >
        <Loader3D backgroundColor={backgroundColor} />
        <Preview3D
          previewState={previewState}
          goldSelectedFoil={goldSelectedFoil}
          silverSelectedFoil={silverSelectedFoil}
          selectedPaper={selectedPaper}
          backgroundColor={backgroundColor}
          isVisibleDevis={isVisibleDevis}
          showOnBottle={showOnBottle}
        />
        <div className={classNames(styles.container, "container")}>
          <div className={classNames(styles.principal, "col-12 row")}>
            <div className={classNames(styles.choosetype, "col-2 row")}>
              <Left3D
                backgroundColor={backgroundColor}
                layers={layers}
                setLayers={setLayers}
                activeLayer={activeLayer}
                setActiveLayer={setActiveLayer}
                selectedPaperName={selectedPaper.name}
                packaging={packaging}
                goldSelectedFoil={goldSelectedFoil}
                silverSelectedFoil={silverSelectedFoil}
                client={client}
                isVisibleDevis={isVisibleDevis}
                isVisibleFoilVar={isVisibleFoilVar}
                setIsVisibleFoilVar={setIsVisibleFoilVar}
              ></Left3D>
            </div>
            <div className={classNames(styles.choosetype, "col-8 row")}></div>
            <div className={classNames(styles.filtershare, "col-2 row")}>
              {previewState.packaging.dieline.name === "flat" && (
                <div
                  className={classNames(styles.bottle_button)}
                  onClick={handleShowOnBottle}
                >
                  <img src={showOnBottle ? bottleOff : bottleOn} alt="bottle" />
                </div>
              )}
              <Right3D
                backgroundColor={backgroundColor}
                setBackgroundColor={setBackgroundColor}
                client={client}
                isVisibleDevis={isVisibleDevis}
                handleToggleVisibleDevis={handleToggleDevis}
              />
            </div>
            {activeLayer && (
              <Bottom3D
                activeLayer={activeLayer}
                goldSelectedFoil={goldSelectedFoil}
                silverSelectedFoil={silverSelectedFoil}
                setGoldSelectedFoil={setGoldSelectedFoil}
                setSilverSelectedFoil={setSilverSelectedFoil}
                selectedPaper={selectedPaper}
                setSelectedPaper={setSelectedPaper}
                isVisibleFoilVar={isVisibleFoilVar}
                setIsVisibleFoilVar={setIsVisibleFoilVar}
                setActiveLayer={setActiveLayer}
              />
            )}
          </div>
        </div>
        <Devis
          isVisibleDevis={isVisibleDevis}
          handleToggleVisibleDevis={handleToggleDevis}
        />
      </div>
    </>
  );
};

export default PreviewScene;

function isDefinedLayer(x: LayerView | undefined): Boolean {
  return x?.enable !== false;
}

function createLawerView(design: DesignPage | undefined): LayerView[] {
  let layerList: LayerView[] = [];
  if (design) {
    layerList = [
      { name: "color", enable: design.color.layer !== undefined },

      {
        name: "displacementMap",
        enable: design.displacementMap?.layer !== undefined,
      },
      {
        name: "goldFoil",
        enable: design.goldFoil?.layer !== undefined,
      },
      {
        name: "holoFoil",
        enable: design.holoFoil?.layer !== undefined,
      },
      {
        name: "silverFoil",
        enable: design.silverFoil?.layer !== undefined,
      },
      {
        name: "varnish",
        enable: design.varnish?.layer !== undefined,
      },
      {
        name: "normalMap",
        enable: design.normalMap?.layer !== undefined,
      },
    ].filter(isDefinedLayer);
  }
  return layerList;
}
