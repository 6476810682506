import { Flat } from "@mgi-labs/preview";

const celestialFlat: Flat = new Flat({
  name: "Celestial",
  paper: {
    layer: { url: "BlackSatin.png", name: "paper" },
  },
  design: {
    recto: {
      color: {
        layer: { url: "color.png", name: "color", width: 1536, height: 2048 },
      },
      silverFoil: {
        layer: { url: "silverFoil.png", name: "Foil Silver" },
      },
      varnish: {
        layer: { url: "varnish.png", name: "Varnish" },
      },
    },
    verso: {
      color: {
        layer: { url: "color2.png", name: "color", width: 1536, height: 2048 },
      },
      silverFoil: {
        layer: { url: "silverFoil2.png", name: "Foil Silver" },
      },
      varnish: {
        layer: { url: "varnish2.png", name: "Varnish" },
      },
    },
    mask: {
      url: "mask.png",
      width: 850,
      height: 1134,
    },
  },
});

export default celestialFlat;
