import classNames from "classnames";
import React from "react";
import { ActiveProjectType } from "./catalogue";
import { Book } from "../../Domain/book";
import styles from "./styles/Left.module.scss";
const Left = ({
  selectedProjectType,
  scrollToProjectType,
  book,
}: {
  selectedProjectType: ActiveProjectType;
  client: string;
  scrollToProjectType: (activeProjectType: ActiveProjectType) => void;
  book: Book;
}) => {
  return (
    <div className={classNames(styles.allpage, "col-12")}>
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.content, "col-12 row")}>


          {/*
          Mis en commentaire pour le moment, et on supprimera cette partie quand Edmond aura validé 
          (En gros c'est si jamais Edmond dis que c'était mieux avant)
          <div className={classNames(styles.titlezone, "col-12 row")}>
            <div className={classNames(styles.nameentreprise, "col-12")}>
              <p>MGI DIGITAL TECHNOLOGY 2020 - 2021</p>
            </div>
          </div> */}
          <div className={classNames(styles.choose, "col-12 row")}>
            <div className={classNames(styles.choosetype, "col-12 row")}>
              {book.categories?.map((category) => (
                <span
                  className={classNames(styles.type, "col-4")}
                  onClick={() => {
                    scrollToProjectType(
                      mapCategoryToActiveProjectType(category.id)
                    );
                  }}
                >
                  <h2
                    className={classNames(
                      selectedProjectType ===
                        mapCategoryToActiveProjectType(category.id) &&
                        styles.selected
                    )}
                  >
                    {category.name}
                  </h2>
                </span>
              ))}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

function mapCategoryToActiveProjectType(categoryId: number): ActiveProjectType {
  switch (categoryId) {
    case 1:
      return "Packaging";
    case 2:
      return "Etiquette";
    case 3:
      return "Support à plat";
    default:
      return "Packaging";
  }
}

export default Left;
