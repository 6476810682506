import {
  BlankPackaging,
  CustomizedPackaging,
  deriveRotationAxes,
  DielineJson,
} from "@mgi-labs/preview";

export const minimalBlankFoldingDieline: DielineJson = {
  name: "casque",
  dimensions: { width: 2004.094, height: 1245.512 },
  faceGeometries: {
    back: {
      height: 644.844,
      width: 676.062,
      x: 268.037,
      y: 333.942,
    },
    bot: {
      height: 225.712,
      width: 676.062,
      x: 1170.136,
      y: 978.786,
    },
    front: {
      height: 644.844,
      width: 676.062,
      x: 1170.136,
      y: 333.942,
    },
    left: {
      height: 644.844,
      width: 226.037,
      x: 944.099,
      y: 333.942,
    },
    right: {
      height: 644.844,
      width: 226.037,
      x: 42,
      y: 333.942,
    },
    top: {
      height: 225.712,
      width: 676.062,
      x: 268.037,
      y: 108.588,
    },
  },
  foldingTree: {
    faceName: "bot",
    children: [
      {
        faceName: "front",
        children: [
          {
            faceName: "left",
            children: [
              {
                faceName: "back",
                children: [{ faceName: "top" }, { faceName: "right" }],
              },
            ],
          },
        ],
      },
    ],
  },
};

const blankCasque: BlankPackaging = new BlankPackaging({
  dieline: deriveRotationAxes(minimalBlankFoldingDieline),
  paper: {
    layer: { url: "BlackSatin.png", name: "paper" },
  },
});

const casque: CustomizedPackaging = new CustomizedPackaging({
  dieline: blankCasque.dieline,
  name: "casque",
  paper: blankCasque.paper,
  design: {
    color: {
      layer: { url: "color.png" },
    },
    silverFoil: {
      layer: { url: "silverFoil.png", name: "Foil Silver" },
    },
    varnish: {
      layer: { url: "varnish.png", name: "Varnish" },
    },
  },
});

export default casque;
