import classNames from "classnames";
import React from "react";
import styles from "./styles/Right3D.module.scss";
import facebook from "../../images/icon/facebook.svg";
import linkedin from "../../images/icon/linkedin.svg";
import facebookw from "../../images/icon/facebookw.svg";
import linkedinw from "../../images/icon/linkedinw.svg";
import twitterw from "../../images/icon/twitterw.svg";
import twitter from "../../images/icon/twitter.svg";

import filtrer from "../../images/icon/close.svg";
import color from "../../images/icon/colors.svg";
import colorw from "../../images/icon/colorsw.svg";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Right3D = ({
  backgroundColor,
  setBackgroundColor,
  client,
  handleToggleVisibleDevis,
  isVisibleDevis,
}: {
  backgroundColor: string;
  setBackgroundColor: React.Dispatch<string>;
  client: string;
  handleToggleVisibleDevis: (
    event: React.SyntheticEvent<Element, Event> | React.KeyboardEvent<Element>
  ) => void;
  isVisibleDevis: Boolean | undefined;
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div
      className={classNames(
        styles.allpage,
        isVisibleDevis === undefined
          ? ""
          : isVisibleDevis
          ? styles.devinotvisible
          : styles.divisvisible,
        "col-12"
      )}
    >
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.content, "col-12 row")}>
          <div
            className={classNames(styles.filterzone, "col-12 row")}
            onClick={() => history.goBack()}
          >
            <div
              className={classNames(
                styles.close,
                backgroundColors.black === backgroundColor ? styles.invert : ""
              )}
            >
              <img
                className={classNames(styles.closeimg)}
                src={filtrer}
                alt="Filtrer"
              />
            </div>
          </div>
          <div className={classNames(styles.socialzone, "col-12 row")}>
            <div className={classNames(styles.social, "col-12 row")}>
              <div
                className={classNames(
                  backgroundColors.black === backgroundColor
                    ? styles.iconw
                    : styles.icon,
                  "col-12"
                )}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/MGIonline"
                >
                  <div data-title={"Facebook"}>
                    <img
                      src={
                        backgroundColors.black === backgroundColor
                          ? facebookw
                          : facebook
                      }
                      alt="Facebook page"
                    />
                  </div>
                </a>
              </div>
              <div
                className={classNames(
                  backgroundColors.black === backgroundColor
                    ? styles.iconw
                    : styles.icon,
                  "col-12"
                )}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/mgi-digital-graphic-technology/"
                >
                  <div data-title={"Linkedin"}>
                    <img
                      src={
                        backgroundColors.black === backgroundColor
                          ? linkedinw
                          : linkedin
                      }
                      alt="Linkedin page"
                    />
                  </div>
                </a>
              </div>

              <div
                className={classNames(
                  backgroundColors.black === backgroundColor
                    ? styles.iconw
                    : styles.icon,
                  "col-12"
                )}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/MGI_HQ"
                >
                  <div data-title={"Twitter"}>
                    <img
                      src={
                        backgroundColors.black === backgroundColor
                          ? twitterw
                          : twitter
                      }
                      alt="Linkedin page"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className={classNames(styles.colorzone, "col-12 row")}>
            <div className={classNames(styles.color)}>
              <img
                className={classNames(styles.colorimg)}
                src={
                  backgroundColors.black === backgroundColor ? colorw : color
                }
                alt="Change color background"
              />
              <div className={classNames(styles.choosecolor)}>
                <div className={classNames(styles.onecolor)}>
                  {backgroundColors.black === backgroundColor && (
                    <div className={classNames(styles.selected)}></div>
                  )}
                  <div
                    className={classNames(
                      styles.black,
                      styles.scale,
                      backgroundColors.black === backgroundColor &&
                        styles.selected
                    )}
                    onClick={() => setBackgroundColor(backgroundColors.black)}
                  ></div>
                </div>
                <div className={classNames(styles.onecolor)}>
                  {backgroundColors.grey === backgroundColor && (
                    <div className={classNames(styles.selected)}></div>
                  )}
                  <div
                    className={classNames(
                      styles.grey,
                      styles.scale,
                      backgroundColors.grey === backgroundColor &&
                        styles.selected
                    )}
                    onClick={() => setBackgroundColor(backgroundColors.grey)}
                  ></div>
                </div>

                <div className={classNames(styles.onecolor)}>
                  {backgroundColors.white === backgroundColor && (
                    <div className={classNames(styles.selected)}></div>
                  )}
                  <div
                    className={classNames(
                      styles.white,
                      styles.scale,
                      backgroundColors.white === backgroundColor &&
                        styles.selected
                    )}
                    onClick={() => setBackgroundColor(backgroundColors.white)}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              styles.contactzone,
              backgroundColors.black === backgroundColor ? styles.black : "",
              backgroundColors.grey === backgroundColor ? styles.gris : "",
              backgroundColors.white === backgroundColor ? styles.white : "",
              "col-12 row"
            )}
          >
            <div
              onClick={(e) => {
                handleToggleVisibleDevis(e);
              }}
              className={classNames(styles.contact)}
            >
              <p className={classNames(styles.contactus)}>{t("Devis")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Right3D;

export const backgroundColors = {
  white: "radial-gradient(circle at 50% 60%, #FAFAFA, #FCFCFC)",
  grey: "radial-gradient(rgb(236 236 236), rgb(208 208 208))",
  black: "radial-gradient(circle at 50% 60%, #323232, #090909)",
};
