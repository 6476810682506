import { Flat } from "@mgi-labs/preview";

const boulangerieFlat: Flat = new Flat({
  name: "boulangerie",
  paper: {
    layer: { url: "BlackSatin.png", name: "paper" },
  },
  design: {
    recto: {
      color: {
        layer: { url: "color.png", name: "color", width: 2048, height: 1455 },
      },
      goldFoil: {
        layer: { url: "goldFoil.png", name: "Foil Gold" },
      },
      silverFoil: {
        layer: { url: "silverFoil.png", name: "Foil Silver" },
      },
      varnish: {
        layer: { url: "varnish.png", name: "Varnish" },
      },
    },
  },
});

export default boulangerieFlat;
