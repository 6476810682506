import { useProgress } from "@react-three/drei";
import classNames from "classnames";
import React from "react";
import styles from "./ChoixProjet/styles/Loader3D.module.scss";

const Loader3D = ({ backgroundColor }: { backgroundColor: string }) => {
  const active = useProgress(({ active }) => active);
  return active ? (
    <div
      className={classNames(
        // Permet de cacher le chargement styles.chargement3Dhide,
        styles.chargement3D,
        "col-12",
        "row"
      )}
      style={{ backgroundColor: backgroundColor }}
    >
      <div>
        <div>
          <span className={classNames(styles.loader)}>
            <span className={classNames(styles.loader_inner)}></span>
          </span>
        </div>
        {/* Si besoin d'ajouter du texte <p>Chargement</p>*/}
      </div>
    </div>
  ) : null;
};

export default Loader3D;
