import {
  BlankPackaging,
  CustomizedPackaging,
  deriveRotationAxes,
  DielineJson,
} from "@mgi-labs/preview";
import { backgroundColors } from "../components/ChoixProjet/right3D";

export const minimalBlankFoldingDieline: DielineJson = {
  name: "whiskyPcd",
  faceGeometries: {
    right: {
      height: 3984,
      width: 1095,
      x: 2919,
      y: 2080,
    },
    front: {
      height: 3984,
      width: 1095,
      x: 1824,
      y: 2080,
    },
    left: {
      height: 3984,
      width: 1095,
      x: 729,
      y: 2080,
    },
    back: {
      height: 3984,
      width: 1095,
      x: 4014,
      y: 2080,
    },
    bot: {
      height: 1095,
      width: 1095,
      x: 1824,
      y: 6064,
    },
    top: {
      height: 1095,
      width: 1095,
      x: 1824,
      y: 984,
    },
  },
  dimensions: { width: 5905, height: 8267 },
  foldingTree: {
    faceName: "bot",
    children: [
      {
        faceName: "front",
        children: [
          { faceName: "left" },
          { faceName: "right", children: [{ faceName: "back" }] },
          { faceName: "top" },
        ],
      },
    ],
  },
};

const blankWhisky: BlankPackaging = new BlankPackaging({
  dieline: deriveRotationAxes(minimalBlankFoldingDieline),
  paper: {
    layer: { url: "EskaDuoWhite.png", name: "paper" },
  },
});

const deerWhisky: CustomizedPackaging = new CustomizedPackaging({
  dieline: blankWhisky.dieline,
  name: "WhiskyDeer",
  paper: blankWhisky.paper,
  sceneBackgroundColor: backgroundColors.black,
  design: {
    color: {
      layer: { url: "blob_full.png" },
    },
    goldFoil: {
      layer: { url: "dorure_full.png" },
    },
    varnish: {
      layer: { url: "vernis_full.png", name: "Varnish" },
    },
    normalMap: {
      layer: { url: "normal_full.png" },
    },
    bumpMap: {
      layer: { url: "bump_full.png" },
    },
  },
});

export default deerWhisky;
